import React from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons'

class JumbotronInfoPack extends React.Component {
  STATE_READY = 'Laden Sie unser Infopaket'
  STATE_SENDING = 'Senden...'
  STATE_SENT = 'Infopaket gesendet'
  STATE_ERROR = 'Versuchen sie noch einmal'

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      current: this.STATE_READY,
    }
  }

  render() {
    const { infoPackUrl } = this.props

    return (
      <div className="infopack">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            <p className="text-muted">
            Wenn Sie mehr über unsere Dienstleistungen erfahren möchten, hinterlassen Sie uns Ihre E-Mail und laden Sie unser Infopack herunter.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            <form
              ref={r => (this.formRef = r)}
              action="#"
              method="post"
              name="infopack_form"
              id="infopack-form"
              onSubmit={event => {
                this.setState({ current: this.STATE_SENDING })
                event.preventDefault()
                axios
                  .post(infoPackUrl, { email: this.state.email })
                  .then(() => {
                    this.setState({
                      email: '',
                      current: this.STATE_SENT,
                    })
                    this.formRef.reset()
                  })
                  .catch(() => {
                    this.setState({
                      current: this.STATE_ERROR,
                    })
                  })
              }}
            >
              <div className="input-group">
                <input
                  onChange={e => this.setState({ email: e.target.value })}
                  type="email"
                  value={this.state.email}
                  className="form-control"
                  placeholder="Deine e-mail"
                  name="email"
                  required
                />
                <span className="input-group-append">
                  <button
                    className={`btn ${
                      this.state.current === this.STATE_SENT
                        ? 'btn-success'
                        : 'btn-primary'
                      }`}
                    name="get_infopack_services_button"
                    type="submit"
                    id={this.state.current === this.STATE_SENT ? 'infopack-sent-btn' : 'get-infopack-btn'}
                  >
                    {this.state.current === this.STATE_SENDING && (
                      <FontAwesomeIcon
                        style={{ marginRight: '0.5rem' }}
                        icon={faSpinner}
                        spin
                      />
                    )}
                    {this.state.current === this.STATE_SENT && (
                      <FontAwesomeIcon
                        style={{ marginRight: '0.5rem' }}
                        icon={faCheck}
                      />
                    )}
                    {this.state.current}
                  </button>
                </span>
              </div>
              <div className="form-group form-check mt-2">
                <input
                  id="infopack-form-gdpr-consent"
                  className="form-check-input"
                  name="gdpr_consent"
                  type="checkbox"
                  required
                />
                <label
                  className="form-check-label small text-muted"
                  htmlFor="infopack-form-gdpr-consent"
                >
                  Ich bin damit einverstanden, Infopaket von Zaven Sp. z o.o. zu erhalten. Die Einwilligung ist freiwillig und ich kann sie jederzeit widerrufen, um die Verarbeitung meiner Daten zu beenden.
                </label>
              </div>
              {this.state.current === this.STATE_ERROR && (
                <p
                  id="form-feedback-invalid"
                  className="mt-1 text-sm text-danger"
                >
                  <em>Bitte stellen Sie sicher, dass Ihre E-Mail-Adresse gültig ist</em>
                </p>
              )}
              {this.state.current === this.STATE_SENT && (
                <p
                  id="form-feedback-success"
                  className="mt-1 text-sm text-muted"
                >
                  <em>
                  Sie können jetzt Ihre E-Mails abrufen. Wenn Sie Schwierigkeiten haben, es zu finden, sollten Sie in Ihrem SPAM-Verzeichnis nachsehen.
                  </em>
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default JumbotronInfoPack
